// .storybook/YourTheme.js

import { create } from '@storybook/theming/create';

export default create({
	base: 'light',
	brandTitle: 'Axios Visuals',
	brandUrl:
		process.env.NODE_ENV === 'production'
			? 'https://axios-visuals-svelte-components.netlify.app/?path=/docs/intro--docs'
			: 'http://localhost:6006/',
	brandImage: 'https://graphics.axios.com/assets/axios/axios-visuals-logo.svg',
	brandTarget: '_self',

	fontBase: '"NB International Pro", "Open Sans", sans-serif'
});
